import actions from '../store/actions/phone-number-actions'

export default{
    namespaced:true,
    state(){
        return{
        }
    },
    actions:{
        ...actions
    },
    // getters:{
    //     ...getters
    // },
    // mutations:{
    //     ...mutations
    // }
}