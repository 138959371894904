import { render, staticRenderFns } from "./createInvestor.vue?vue&type=template&id=0d9504f7&"
import script from "./createInvestor.vue?vue&type=script&lang=js&"
export * from "./createInvestor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports